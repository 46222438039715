import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";

import {t} from '@client/i18n/localize'
import {tracker} from '@client/utils/tracking/gtm'

const setConsent = ({ cookie, changedCategories }, updated = false) => {
  const marketingAllowed = CookieConsent.acceptedCategory('marketing')
  const analyticsAllowed = CookieConsent.acceptedCategory('analytics')

  const gtm = tracker()
  const payload = {
    consent: {
      mode: updated ? 'update' : 'default',
      ad_storage: marketingAllowed ? 'allowed' : 'denied',
      analytics_storage: analyticsAllowed ? 'allowed' : 'denied',
    }
  }

  if (marketingAllowed) {
    payload.event = 'cookie_consent'
    // Start Bloomreach after consent
    if (window.exponea) {
      exponea.start();
    }
  }

  gtm(payload)
}

$(document).on('turbolinks:load', () => {
  if (window.testMode) {
    return
  }

  CookieConsent.reset()
  CookieConsent.run({
    onConsent: (consent) => setConsent(consent),
    onChange: (consent) => setConsent(consent, true),

    categories: {
      necessary: {
        enabled: true,
        readOnly: true
      },
      analytics: {
        enabled: true,
        readOnly: true
      },
      marketing: {
        enabled: false,
        readOnly: false,
        autoClear: {
          reloadPage: true,
          cookies: [
            {
              name: /^(__exponea)/, // Bloomreach cookies
            },
            {
              name: /^(xnpe_)/, // Bloomreach cookies
            }
          ]
        }
      }
    },

    guiOptions: {
      consentModal: {
        layout: 'box',
        position: 'bottom left',
      }
    },

    language: {
      default: window.LOCALE,
      translations: {
        [window.LOCALE]: {
          consentModal: {
            title: t('cookieconsent.consent_modal.title'),
            description: t('cookieconsent.consent_modal.description'),
            acceptAllBtn: t('cookieconsent.accept_all'),
            acceptNecessaryBtn: t('cookieconsent.reject_all'),
            showPreferencesBtn: t('cookieconsent.consent_modal.show_preferences'),
          },
          preferencesModal: {
            title: t('cookieconsent.preferences_modal.title'),
            acceptAllBtn: t('cookieconsent.accept_all'),
            acceptNecessaryBtn: t('cookieconsent.reject_all'),
            savePreferencesBtn: t('cookieconsent.preferences_modal.save_preferences'),
            sections: [
              {
                title: '',
                description: t('cookieconsent.preferences_modal.section_header.description'),
              },
              {
                title: t('cookieconsent.preferences_modal.functional.title'),
                description: t('cookieconsent.preferences_modal.functional.description'),
                linkedCategory: 'necessary'
              },
              {
                title: t('cookieconsent.preferences_modal.analytics.title'),
                description: t('cookieconsent.preferences_modal.analytics.description'),
                linkedCategory: 'analytics'
              },
              {
                title: t('cookieconsent.preferences_modal.marketing.title'),
                description: t('cookieconsent.preferences_modal.marketing.description'),
                linkedCategory: 'marketing'
              },
              {
                title: t('cookieconsent.preferences_modal.section_footer.title'),
                description: t('cookieconsent.preferences_modal.section_footer.description_html'),
              }
            ]
          }
        }
      }
    },
  });
})
