import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import cartReducer from './reducers/cart'
import wishlistsReducer from './reducers/wishlists'
import timeslotsReducer from './reducers/timeslots'
import depotsReducer from './reducers/depots'
import pricesReducer from './reducers/prices'
import pickerReducer from './reducers/picker'
import openingTimesReducer from './reducers/openingTimes'
import userReducer from './reducers/user'
import domainReducer from './reducers/domain'
import companyReducer from './reducers/company'

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
  cart: cartReducer,
  wishlists: wishlistsReducer,
  timeslots: timeslotsReducer,
  depots: depotsReducer,
  prices: pricesReducer,
  picker: pickerReducer,
  openingTimes: openingTimesReducer,
  user: userReducer,
  domain: domainReducer,
  company: companyReducer
})

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
)

export default store
