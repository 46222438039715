if (window.ENVIRONMENT === 'test') {
  window.timekeeper = require('timekeeper');
  timekeeper.travel(window.CURRENT_DATE);
}

// Make Rails UJS work
import Rails from '@rails/ujs'
Rails.start()

// 3rd party libraries
import '@popperjs/core';

// Initializers
import { i18n } from '../../../app/javascript/packs/localized-i18n'
import './initializers/collapse'
import './initializers/cookieconsent'
import './initializers/turbolinks'
import './initializers/session'
import './initializers/toggle'
import './initializers/clickable'
import './initializers/infiniteScroll'
import './initializers/carousel'
import './initializers/tab'
import './initializers/mobileMenu'
import './initializers/tracking'
import './initializers/stickyHeader'
import './initializers/localeSelect'
import './initializers/vatSelect'
import './initializers/dropdown'
import './initializers/tooltips'
import './initializers/lazyVideo'
import './initializers/pageOverlay'

// Tweakwise
import './initializers/tws'
import './initializers/tws-cart'

// Behavior for specific pages
import './pages/checkout'
import './pages/reviews'
import './pages/transaction'

// ServiceWorker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
    .then(function (reg) {
    }).catch(function (err) {
      console.log("No it didn't. This happened:", err)
    });
}
